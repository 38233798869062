.speech-bubble-left {
  background: #e4e3e3;
  position: relative;
  border-radius: 0.4em;
  min-width: 50px;
  min-height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
}

#neweditor > div.ql-container.ql-snow > div.ql-editor {
  height: 26vh;
}

div.ui.fluid.input.square > input[type="text"] {
  border-radius: 0;
}

div.ui.fluid.search.selection.upward.dropdown.square {
  border-radius: 0;
}

.speech-bubble-left:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.406em solid transparent;
  border-right-color: #e4e3e3;
  border-left: 0;
  margin-top: -0.406em;
  margin-left: -0.406em;
}

.speech-bubble-right {
  position: relative;
  background: #347cbe;
  border-radius: 0.4em;
  min-width: 50px;
  min-height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  color: white;
}

.filepond--root {
  margin-bottom: 0;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  min-height: 100px;
  z-index: 100 !important;
}

div.ql-toolbar.ql-snow {
  min-height: 60px;
}

.filepond--browser {
  /* z-index: 100 !important; */
  min-height: 100px;
}

#conversationContainer > div:nth-child(4) > div > div {
  border-right-style: none;
}

.speech-bubble-right:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.406em solid transparent;
  border-left-color: #347cbe;
  border-right: 0;
  margin-top: -0.406em;
  margin-right: -0.406em;
}
