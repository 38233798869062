@font-face {
  font-family: "EUROCAPS";
  src: url("../assets/font/EUROCAPS.TTF");
}

.circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* width and height can be anything, as long as they're equal */
}

@font-face {
  font-family: "CenturyGothicRegular";
  src: url("../assets/font/CenturyGothicRegular.ttf");
}

@font-face {
  font-family: "Telegren";
  src: url("../assets/font/telegren.ttf");
}

@font-face {
  font-family: "Sintony-Regular";
  src: url("../assets/font/Sintony-Regular.ttf");
}

html,
body {
  margin: 0;
  padding: 0;
}

.b-container {
  background: black;
  flex: 1;
}

.fancy {
  line-height: 0.5;
  text-align: center;
}
.fancy span {
  display: inline-block;
  position: relative;
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid grey;

  top: 0;
  width: 600px;
}
.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

/* loader */

.loader,
.loader__figure {
  position: absolute;
  top: 6em;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader {
  overflow: visible;
  padding-top: 2em;
  height: 0;
  width: 2em;
}

.loader__figure {
  height: 0;
  width: 0;
  box-sizing: border-box;
  border: 0 solid #fe665c;
  border-radius: 50%;
  -webkit-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loader__label {
  float: left;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0.5em 0 0 50%;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #8d3b3e;
  white-space: nowrap;
  -webkit-animation: loader-label 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-label 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-label 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fe665c;
  }
  29% {
    background-color: #fe665c;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fe665c;
  }
  29% {
    background-color: #fe665c;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fe665c;
  }
  29% {
    background-color: #fe665c;
  }
  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
@-webkit-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@-moz-keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes loader-label {
  0% {
    opacity: 0.25;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

#noGutter {
  margin-left: 0px !important;
  margin-right: 0px !important;
  height: 100% !important;
}

@media only screen and (max-width: 767px) {
  .noShowMobile {
    display: none !important;
  }
  #noGutter {
    flex-grow: 1;
  }
  .activeNewTicket {
    position: absolute;
    width: 100% !important;
  }
  .noIndentMobile {
    padding-left: 0;
  }
}
